<template>
    <div>
        <s-toolbar dark
             color="primary" label="Creacion de Linea y Estaciones de trabajo"> 
            <v-btn icon
                fab 
                color="grey-lighten-4"
                @click="viewDetail()"
                x-small >
                <v-icon color="danger">
                    fa-brands fa-folder
                </v-icon>
            </v-btn>     
        </s-toolbar>
        <v-card class="pa-md-2 mx-lg-auto">
            <v-container grid-list-xs class="ma-0 pa-0">
            <v-row>
                <v-col lg="12" cols="12" style="padding-bottom: 10px;">
                    <v-card-title class="primary--text">Lineas</v-card-title>
                    <v-card-text>
                        <v-container grid-list-xs class="ma-0 pa-0">
                            <v-row>
                                <v-col cols="3" lg="3" md="6" sm="12">
                                    <s-text
                                        label="Descripcion"
                                        v-model="LncDescription"
                                        ref="txtLncDescription">

                                    </s-text>
                                </v-col>

                                <v-col cols="4" lg="2" md="6" sm="12">
                                    <s-select-definition
                                        label="Cultivo"
                                        v-model="TypeCultive"
                                        :def="1173"
                                        
                                    ></s-select-definition>
                                </v-col>

                                <v-col cols="3" lg="3" md="6" sm="12">	
                                     <!-- :items="ProductionCategory"
                                        item-value="PdcID"
                                        item-text="PdcDescription"
                                        label="Tipo de Congelamiento"
                                        v-model="PdcID"
                                        :cultiveID="TypeCultive"
                                        ref="txtPdcID" -->						
                                    <s-select                                      

                                        :items="ProductionCategory"
                                        v-model="CecID"  
                                        item-value="CecID"
                                        item-text="CecDescription"
                                        label="Salida de Congelamiento"
                                                                      
                                        ref="txtCecID"
                                    >
                                    </s-select>
                                </v-col>  
                                <v-col cols="3" lg="2" md="6" sm="12"> 
                                    <s-select-definition 
                                        :def=1154 label="Estado" 
                                        v-model="LncState">
                                    </s-select-definition>  
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn density="compact" 
                                        fab 
                                        style="margin: 10px"
                                        rounded
                                        elevation="2" 
                                        x-small 
                                        @click="SaveLine()">
                                        <v-icon color="primary">
                                            fa-plus
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row>
                <v-col lg="12" cols="12" style="padding-bottom: 0px;">
                    <v-card-title class="primary--text">
                        <v-badge dot>
                            Estacion de Trabajo
                        </v-badge>
                    </v-card-title>
                    <v-card-text>     
                        <v-container grid-list-xs class="ma-0 pa-0">
                            <v-row>
                                <v-col cols="4" lg="4" md="6" sm="12">
                                    <s-text
                                        label="Sigla"
                                        v-model="CttName"
                                        ref="txtCttName"
                                    ></s-text>
                                </v-col>
                                <v-col cols="1" lg="1" md="1" sm="2">
                                    <s-text
                                        label="Cant. Mesas"
                                        type="number"
                                        v-model="NumTable"
                                        ref="txtNumTable"
                                        :min="1"
                                    ></s-text>
                                </v-col>

                                <v-col cols="4" lg="2" md="6" sm="12">
                                    <s-text
                                        label="Capacidad"
                                        v-model="CttCapacity"
                                        :min="0"
                                        :max="6"
                                        ref="txtCapacidad"
                                        type="number"
                                    ></s-text>
                                </v-col>

                                <v-col v-if="NumTable == 1" cols="4" lg="2" md="6" sm="12">							
                                    <s-select-definition
                                        label="Posición"
                                        v-model="CttPosition"
                                        :def="1426"
                                        ref="txtPosition"
                                    ></s-select-definition>
                                </v-col>

                                <v-col cols="4" lg="4" md="6" sm="12">
                                    <s-select
                                        label="Linea"
                                        :items="listLineas"
                                        v-model="LncID"
                                        item-value="LncID"
                                        item-text="LineDescriptionName"      
                                        ref="txtLinea"        
                                    ></s-select>
                                </v-col>

                                <v-col cols="4" lg="2" md="6" sm="12">
                                    <s-select-definition
                                        label="Estado"
                                        v-model="CttStatus"
                                        :def="1154"
                                    ></s-select-definition>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn density="compact" 
                                        fab 
                                        style="margin: 10px"
                                        rounded
                                        elevation="2" 
                                        x-small 
                                        @click="SaveStationWork()">
                                        <v-icon color="primary">
                                            fa-solid fa-plus
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>   
                    </v-card-text>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-dialog              
            v-model="dialogDetail" 
            v-if="dialogDetail"
            transition="dialog-bottom-transition"
            persistent
            fullscreen>
            <line-station-detail  @close="dialogDetail = false">

            </line-station-detail>
        </v-dialog>
    </div>
</template>
<script>
    import _sCreationTableService from "@/services/FrozenProduction/FrzCreateTableService.js"
    import _sProductionLine from '@/services/FrozenProduction/ProductionLine.js';    
    import _sFrzTypeExitCategoryService from "@/services/FrozenProduction/FrzTypeExitCategory.js"

    import LineStationDetail from './FrzLineStationWorkDetail.vue'
   
    import _sProductionCategory from '../../../services/FrozenProduction/ProductionCategory.js';
    export default{
        components:{
            LineStationDetail
        },
        data(){
            return {
                listLineas: [],
                LncDescription: '',
                PdcID: 0,
                TypeCultive: 0,
                LncState: 0,
                ProductionCategory: [],
                CttName: '',
                CttCapacity: 0,
                CttPosition: 0,
                LncID: 0,
                CttStatus: 0,
                dialogDetail: false,
                CecID: 0,
                NumTable: 1,
                StationWork: {}
            }
        },
        methods:{

            inputProdCategory(typeCultive){                
                _sProductionCategory.list(this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        
                        this.ProductionCategory = r.data;                    
                    }
                });   
            },
            SaveLine()
            {
                let line = {};
                if(this.LncDescription == '')
                {
                    this.$fun.alert("Ingrese Descripcion de Linea", "warning")
                    this.$refs.txtLncDescription.focus();
                    return;
                }
                if(this.txtCecID == 0)
                {
                    this.$fun.alert("Seleccione Tipo salida de congelamiento", "warning")
                    this.$refs.txtCecID.focus();
                    return;
                }
                line.LncDescription = this.LncDescription;
                line.CecID = this.CecID;
                line.TypeCultive = this.TypeCultive; 
                line.LncState = this.LncState;
                
                this.$fun.alert("Estas seguro de guardar ?", "question").then((r) => {
                    if(r.value){
                        _sProductionLine.save(line, this.$fun.getUserID())
                        .then((r) => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Registrado correctamente", "success");
                                this.refresLines();
                            }
                        });
                    }
                });
                
            },
            SaveStationWork(){
                
                if(this.CttName == '')
                {
                    this.$fun.alert("Ingrese Descripcion de Estacion de trabajo", "warning")
                    this.$refs.txtCttName.focus();
                    return;
                }
                this.StationWork.CttName = this.CttName;
                this.StationWork.CttCapacity = this.CttCapacity;
                this.StationWork.CttPosition = this.CttPosition; 
                           
                this.StationWork.LncID = this.LncID;
                this.StationWork.CttStatus = this.CttStatus;
                this.StationWork.UsrCreateID = this.$fun.getUserID();
                if(this.NumTable > 20)
                {
                    this.$fun.alert("Debe ingresar una cantidad menor de mesas", "error")
                    return;
                }
                this.$fun.alert("Estas seguro de guardar ?", "question").then((r) => {
                    if(r.value){
                        _sCreationTableService.saveNumTable(this.StationWork, this.NumTable, this.$fun.getUserID())
                        .then((r) => {
                            if(r.status == 200)
                            {
                                this.$fun.alert("Registrado correctamente", "success");
                                this.CttName = '';
                                this.CttCapacity = 0;
                            }
                        });
                    }
                });
            },
            refresLines()
            {
                _sCreationTableService
                .list(this.$fun.getUserID())
                .then((r) => {
                    this.listLineas = r.data;                
                    if (this.listLineas.length > 0) {
                        this.LncID = r.data[0].LncID;
                        
                    }
                });

                this.LncDescription = '';
                this.PdcID = 0;
                
            },
            viewDetail(){
                this.dialogDetail = true;
            }
        },
        created(){
            this.refresLines();
            this.inputProdCategory(0);
        }
    }
</script>